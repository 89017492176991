/****** PLACE YOUR CUSTOM STYLES HERE ******/

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@font-face {
    font-family: 'GothamBold';
    src: url('../fonts/GothamBold.woff') format('woff'), url('../fonts/GothamBold.ttf') format('truetype'), url('../fonts/GothamBold.svg#GothamBold') format('svg'), url('../fonts/ufonts.com_gotham-bold.woff2') format('woff2'), url('../fonts/ufonts.com_gotham-bold.eot'), url('../fonts/ufonts.com_gotham-bold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Lato', sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'GothamBold', sans-serif;
}

.btn {
    font-family: 'Lato', sans-serif;
    border: 2px solid #B1654E;
    color: #ffffff;
}

a {
    color: #B1654E;
}

a:hover {
    color: #000000;
}

.btn:visited {
    color: #fff;
}

nav.transparent.fixed .menu>li>a.active {
    opacity: 1 !important;
}

.menu {
    font-family: 'Lato', sans-serif;
}

.fullscreen .btn {
    font-size: 20px;
}

.fullscreen i {
    font-weight: 800;
}


/* Footer */

@media screen and (max-width: 768px) {
    .footer-1 .row {
        text-align: center;
    }
    .footer-1 .row .text-right {
        text-align: center !important;
        margin-top: 15px;
    }
}


/* Feature Pagine Servizi */

.feature ul li p {
    font-weight: 600;
    color: #232323;
    margin-bottom: 30px !important;
    font-size: 20px;
}


/* Pagine Contatti */

.btn-filled {
    background: #B1654E;
    border-color: #B1654E;
}

.btn:hover {
    background: transparent;
    color: #B1654E !important;
}


/* Cookies Banner */

.cookie-banner {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 30%;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px #232323;
    padding: 15px;
}

@media screen and (max-width: 768px) {
    .cookie-banner {
        width: 100%;
        left: 0;
        right: 0;
    }
}

.cookie-banner .btn {
    margin-bottom: 0;
    background: #b1654e;
}

.cookie-banner .btn:hover {
    border-color: #b1654e;
    background: transparent;
}
